(function () {
  var d = document;

  function getOffsetTop(el) {
    var rect = el.getBoundingClientRect(),
        top = rect.top + (d.documentElement || d.body.parentNode || d.body).scrollTop;
    return Math.round(top);
  }

  function toggleClass (el, cls) {
    var currentClass = el.className;
    if (currentClass.indexOf(cls) < 0) {
      el.className += ' ' + cls;
    } else {
      el.className = currentClass.replace(cls, '');
    }
  }

  function toggleClassLinstener(el, target, cls) {
    el.addEventListener('click', function(e) {
      e = e || window.event;
      e.preventDefault ? e.preventDefault() : (e.returnValue = false);

      toggleClass(target, cls);
    });
  }

  var togglers = d.querySelectorAll('.js-class-toggler');
  if (togglers.length > 0) {
    [].forEach.call(togglers, function(el) {
      var target = d.querySelector(el.getAttribute('data-target')),
          cls = el.getAttribute('data-class');
      if (target && cls) {
        toggleClassLinstener(el, target, cls);
      }
    });
  }

  // == image expand ==
  var expandControls = d.querySelectorAll('.js-expand-control');
  if (expandControls.length > 0) {
    [].forEach.call(expandControls, function(el) {
      var target = el;
      while (target.nodeName.toLowerCase() !== 'figure') {
        target = target.parentNode;
      }
      toggleClassLinstener(el, target, 'expanded');
    });
  }

  // scroll to top
  // https://github.com/ganlanyuan/scrollTo
  var scrollTo=function(){return function(t,e){function o(t,e,n){if(!(n<0)){var i,c=e-t.scrollTop,r=c/n*10,l=function(){t.scrollTop+=r,t.scrollTop!==e&&(o(t,e,n-10),i=!1)};i||(i=!0,window.requestAnimationFrame?window.requestAnimationFrame(l):setTimeout(l,10))}}var n=d.body,i=d.documentElement,c=(i||n.parentNode||n).clientHeight,r=Math.max(n.scrollHeight,n.offsetHeight,i.clientHeight,i.scrollHeight,i.offsetHeight),l=t+c,u=r-c-1;l>r&&(t=u),t=Math.round(t),o(d.body,t,e),o(d.documentElement,t,e)}}();

  d.querySelector('.js-top').addEventListener('click', function (){
    scrollTo(0, 300); // scroll to 0, duration 300 ms
  });

  // article: scroll to comment
  var comment = d.querySelector('.toolline .comment');
  if (comment) {
    comment.addEventListener('click', function(e) {
      var commentBox = d.querySelector('.comment-box');

      if (commentBox) {
        e = e || window.event;
        e.preventDefault ? e.preventDefault() : (e.returnValue = false);

        scrollTo(getOffsetTop(commentBox), 300);
      }
    });
  }

  // home: toggle more-sharetool
  var shareBtn = d.querySelector('.home-video-social .share'),
      shareTool = d.querySelector('.home-video-social .more-sharetool');
  if (shareBtn && shareTool) {
    shareTool.className = shareTool.className.replace('show', '');

    shareBtn.addEventListener('click', function(e) {
      e = e || window.event;
      e.preventDefault ? e.preventDefault() : (e.returnValue = false);

      toggleClass(shareTool, 'show');
      toggleClass(shareBtn, 'on');
    });
  }
})();